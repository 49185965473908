<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="category"
      :search="search"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Categorias de Compliance</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar "
            single-line
            hide-details
          />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
      <template #[`item.active`]="{ item }">
        <v-checkbox
          :input-value="item.active"
          class="primary--text"
          value
          disabled
        />
      </template>
      <template #[`item.createdAt`]="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-category
        v-if="dialog"
        :populate-with="categoryToEdit"
        @category-added="addCategory"
        @category-edited="editCategory"
        @close-dialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>

import ComplianceCategoryService from '../../../services/compliance-category.service';
// import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'ComplianceCategory',
  components: {
    'form-category': () => import('./Form'),
    // DeleteConfirmation,
  },
  data: () => ({
    headers: [
      {
        text: 'Titulo da Categoria',
        sortable: false,
        value: 'title',
      },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    category: [],
    search: '',
    dialog: false,
    categoryToEdit: {},
    // dialogDeleteConfirmation: false,
    // dialogDelete: true,
    // deleteItemID: null,
  }),

  async created() {
    await this.loadCategories();
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.categoryToEdit = {};
    },
    async loadCategories() {
      const data = await ComplianceCategoryService.getCategory();
      this.category = data;
    },
    addCategory(category) {
      this.category.push(category);
      this.dialog = false;
      this.loadCategories();
    },

    editCategory(category) {
      const index = this.category.findIndex((listCategory) => listCategory.id === category.id);
      this.category[index] = category;
    },

    editItem(category) {
      this.categoryToEdit = category;
      this.dialog = true;
    },

    // prepareToDelete(item) {
    //   this.dialogDeleteConfirmation = true;
    //   this.deleteItemID = item.id;
    // },

    // async deleteItem() {
    //   try {
    //     this.dialogDeleteConfirmation = false;
    //     const itemId = this.deleteItemID;
    //     await ComplianceCategoryService.deleteCategory(itemId);
    //     this.$toast.success('Categoria excluída com sucesso');
    //     const index = this.category.findIndex((listCategory) => listCategory.id === this.deleteItemID);
    //     this.category.splice(index, 1);
    //   } catch (e) {
    //     this.$handleHttpError(e);
    //   }
    // },

  },
};
</script>
